'use strict';

var slickConfigs = require('org/config/slickConfigs');

$(document).ready(function () {
    const $lmCarousels = $('.js-layout-carousel');
    const $lmEinsteeinSlot = $('.js-lm-einstein-slot');
    const $heroFiftyCarousel = $('.js-hero-fifty-carousel');

    /**
     * Initialize carousel
     * @param {JQuery} $carousel - carousel jQuery object
     * @returns {void}
     */
    function initCarousel($carousel) {
        if ($carousel.is('.slick-initialized')) return;

        const $firstSlide = $carousel.children().first();
        const slidesToShow = $carousel.width() / $firstSlide.outerWidth();

        if ($carousel.hasClass('js-lm-wishlist-carousel')) {
            $carousel.slick(slickConfigs.wishlistCarousel);
        } else if ($carousel.hasClass('js-shop-your-way-carousel')) {
            $carousel.slick({
                infinite: false,
                slidesToScroll: 2,
                slidesToShow: slidesToShow,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        } else if ($carousel.hasClass('js-lm-einstein-carousel')) {
            const isDrLanding = $('.js-diamond-room-landing').length > 0;

            $carousel.slick({
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: isDrLanding ? 4 : slidesToShow,
                arrows: isDrLanding,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            arrows: true,
                            slidesToShow: isDrLanding ? 3 : slidesToShow
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: isDrLanding ? 3 : slidesToShow
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            arrows: false,
                            slidesToShow: isDrLanding ? 1 : slidesToShow
                        }
                    }
                ]
            });
        } else if ($carousel.is('[data-slick]')) {
            try {
                var carouselConfigs = JSON.parse($carousel.attr('data-slick'));
                $carousel.slick(carouselConfigs);
            } catch (e) {
                $carousel.slick();
            }
        } else {
            $carousel.slick({
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: slidesToShow
            });
        }
    }

    /**
     * Initialize L&M Studio carousels
     * @returns {void}
     */
    function initCarousels() {
        $lmCarousels.each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                initCarousel($(this));
            }
        });
    }

    initCarousels();

    $lmEinsteeinSlot.on('einstein:loaded', function () {
        var $this = $(this);
        var $productTiles = $this.find('.product');
        var $carouselContainer = $this.closest('.js-layout-carousel-container');
        var $carousel = $carouselContainer.find('.js-lm-einstein-carousel');

        $productTiles.each(function () {
            $carousel.append($(this).clone());
        });
        if ($productTiles.length) {
            $carouselContainer.removeClass('d-none');
        }

        initCarousel($carousel);
    });

    if ($heroFiftyCarousel.length) {
        let settings = {
            mobileFirst: true,
            arrows: false,
            infinite: false,
            slidesToShow: 1.07,
            responsive: [
                {
                    breakpoint: 767,
                    settings: 'unslick'
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1.18
                    }
                }
            ]
        };

        $heroFiftyCarousel.slick(settings);

        $(window).on('resize', function () {
            if ($(window).width() > 767) {
                return;
            }

            if (!$heroFiftyCarousel.hasClass('slick-initialized')) {
                $heroFiftyCarousel.slick(settings);
            }
        });
    }
});
